.my-shadow {
  background-color: #000000;
}
.my-shadow div {
  filter: blur(15px);
  background: red;
  position: absolute;
  height: 100%;
  width: 97%;
  top: 50%;
  border-radius: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hexagon {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.honeycomb-bg-black {
  background: center
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(0,0,0, 0.1)' viewBox='0 0 100 169.5'%3E%3Cpolygon points='50,34.75 93.5,59.75 93.5,109.75 50,134.75 6.5,109.75 6.5,59.75'%3E%3C/polygon%3E%3Cpolygon points='0,-50 43.5,-25 43.5,25 0,50 -43.5,25 -43.5,-25'%3E%3C/polygon%3E%3Cpolygon points='100,-50 143.5,-25 143.5,25 100,50 56.5,25 56.5,-25'%3E%3C/polygon%3E%3Cpolygon points='0,119.5 43.5,144.5 43.5,194.5 0,219.5 -43.5,194.5 -43.5,144.5'%3E%3C/polygon%3E%3Cpolygon points='100,119.5 143.5,144.5 143.5,194.5 100,219.5 56.5,194.5 56.5,144.5'%3E%3C/polygon%3E%3C/svg%3E");
  background-size: 16px;
}

.honeycomb-bg-green {
  background: center
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(0, 204, 156, 0.3)' viewBox='0 0 100 169.5'%3E%3Cpolygon points='50,34.75 93.5,59.75 93.5,109.75 50,134.75 6.5,109.75 6.5,59.75'%3E%3C/polygon%3E%3Cpolygon points='0,-50 43.5,-25 43.5,25 0,50 -43.5,25 -43.5,-25'%3E%3C/polygon%3E%3Cpolygon points='100,-50 143.5,-25 143.5,25 100,50 56.5,25 56.5,-25'%3E%3C/polygon%3E%3Cpolygon points='0,119.5 43.5,144.5 43.5,194.5 0,219.5 -43.5,194.5 -43.5,144.5'%3E%3C/polygon%3E%3Cpolygon points='100,119.5 143.5,144.5 143.5,194.5 100,219.5 56.5,194.5 56.5,144.5'%3E%3C/polygon%3E%3C/svg%3E");
  background-size: 16px;
}

.flip {
  transform: rotateY(180deg);
}

.flipped-text {
  transform: rotateY(180deg);
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.circle-inner-shadow {
  box-shadow: 0px 4px 10px 0px rgba(18, 208, 163, 0.2) inset;
}

.circle-inner-shadow-black {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2) inset;
}

/* New  */
.parent {
  perspective: 2500px;
}
.inner-content {
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.innner-content-flipped {
  transform: rotateY(180deg);
}
.front-card,
.back-card {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.back-card {
  transform: rotateY(180deg);
}
